@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto Condensed', sans-serif;
  }

  .left-image {
    animation: slideInFromLeft 1s forwards;
  }
  
  .right-image {
    animation: slideInFromRight 1s forwards;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateY(-50%) translateX(0%);
    }
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateY(-50%) translateX(0%);
    }
  }

  .fade-enter {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  
  .fade-enter-active {
    opacity: 1;
  }
}